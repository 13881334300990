<template>
	<div class="mform-inner edit_inner">
		<div class="mform-head" v-if="globalStyle.formHeader.isShow">
			<div v-if="globalStyle.formHeader.type === '文字'" class="txt" key="mFormHeadText">{{globalStyle.formHeader.textCont}}</div>
			<div v-else class="img" key="mFormHeadImg">
				<img v-if="globalStyle.formHeader.imgSrc" :src="globalStyle.formHeader.imgSrc">
			</div>
		</div>
		<div class="medit-head">
			<div class="mform-title" v-if="mGlobalStyle.formTitle.isShow">{{globalStyle.formTitle.textCont}}</div>
			<div class="mform-descript" v-if="mGlobalStyle.formDescript.isShow">{{globalStyle.formDescript.textCont}}</div>
		</div>
		<div class="mform-widget">
			<div class="mform-list">
				<div class="mform-item" v-for="(item,index) in list" :key="index">
					<mQuesWidget :item="item" :globalItemStyle="globalItemStyle" :list="list" :logicFlag="logicFlag"/>
					<div class="cover" v-show="JSON.stringify(astrictControl)=='{}'||quesDataId"></div>	
				</div>
			</div>
		</div>
		<el-form :model="form" label-width="80px" class="yzm" v-if="verifyType == 1">
			<el-form-item label="验证码" prop="gifCode" :rules='[{ required:true,message:"验证码不能为空", trigger: "blur" }]'>
				<el-input v-model="form.gifCode"></el-input>
			</el-form-item>
			<el-form-item>
				<el-image :src="verificationCode" @click="getGifCode"></el-image>
			</el-form-item>
		</el-form>
		<div class="submit_btn msubmit_btn">
			<el-button type="primary" @click="formSubmit" v-if="logicFlag && !quesDataId">{{mGlobalStyle.submitBtnStyle.text}}</el-button>
			<el-button type="primary" v-else >{{mGlobalStyle.submitBtnStyle.text}}</el-button>
		</div>
		<template v-if="logicFlag">
			<div class="mform-cover" v-if=" mGlobalStyle.formCover.isShow && mGlobalStyle.formCover.btnStyle.active == '开始按钮'">
				<div class="title-cover" v-show="mGlobalStyle.formCover.titleShow">{{globalStyle.formTitle.textCont}}</div>
				<div class="btn-cover">
					<el-button type="primary" @click="closeCover">{{mGlobalStyle.formCover.btnStyle.text}}</el-button>
				</div>
			</div>
			<el-collapse-transition>
			<div class="mform-cover" v-show="mGlobalStyle.formCover.isShow && mGlobalStyle.formCover.btnStyle.active != '开始按钮'" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
				<div class="title-cover" v-show="mGlobalStyle.formCover.titleShow">{{globalStyle.formTitle.textCont}}</div>
				<!-- <div class="btn-cover">
					<el-button type="primary">{{mGlobalStyle.formCover.btnStyle.text}}</el-button>
				</div> -->
			</div>
			</el-collapse-transition>
		</template>
		<template v-else>
			<div class="mform-cover" v-if=" mGlobalStyle.formCover.isShow && mGlobalStyle.formCover.btnStyle.active == '开始按钮'">
				<div class="title-cover" v-show="mGlobalStyle.formCover.titleShow">{{globalStyle.formTitle.textCont}}</div>
				<div class="btn-cover">
					<el-button type="primary" @click="closeCover">{{mGlobalStyle.formCover.btnStyle.text}}</el-button>
				</div>
			</div>
			<div class="mform-cover" v-if=" mGlobalStyle.formCover.isShow && mGlobalStyle.formCover.btnStyle.active != '开始按钮'" @click="closeCover">
				<div class="title-cover" v-show="mGlobalStyle.formCover.titleShow">{{globalStyle.formTitle.textCont}}</div>
				
			</div>
		</template>
			
	</div>
</template>
<script>
import $ from "jquery";
import { get,post,getGifCode } from "/src/services/ajax_ucenter.js";
import mQuesWidget from "@/components/mQuesWidget";
export default {
	components: {
		mQuesWidget
	},
	props:{
		logicFlag:{
			type:Boolean
		},
		astrictControl:{
			type:Object
		}
	},
	data() {
		return {
			sizeRatio: 1,
			formToken:sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			identityType:sessionStorage.getItem('identityType')?sessionStorage.getItem('identityType'):'',
			formId:'',
			quesDataId:'',
			verifyType:0,
			verificationCode:'',
			form:{
				gifCode:''
			},
			urlType:0,
			userId:'',
			globalStyle: {
				themeColor: '',
				formWidth: '',
				contStyle: {},
				submitBtnStyle: {},
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {}
			},
			globalItemStyle:{
				itemStyle: {},
				labelStyle: {},
				mItemStyle: {},
				mLabelStyle: {},
			},
			mGlobalStyle: {
				formCover: {},
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {},
				contStyle: {},
				inputStyle: {},
				submitBtnStyle: {}
			},
			list: [],
			canAnswer: 1,
			cantMessage: '',
			startPassword: 0,
			password: '',
			userInputPsd: '',
			startY: 0, // 触摸开始的Y坐标
			endY: 0, // 触摸结束的Y坐标
			startX: 0, // 触摸开始的X坐标
			endX: 0, // 触摸结束的X坐标
		}
	},
	mounted(){
		this.identityType = this.$route.query.identityType?this.$route.query.identityType:sessionStorage.getItem('identityType');
		this.formId = this.$route.query.formId?this.$route.query.formId:sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'';
		this.quesDataId = this.$route.query.dataId?this.$route.query.dataId:'';
		this.email = this.$route.query.email?this.$route.query.email:'';
		this.userId = this.$route.query.userId?this.$route.query.userId:'';
		
		if(this.identityType == 'admin'){
			if(this.userId){
				this.getTplFormData(this.formId); //超管后台查看用户发布到答题广场的表单
			}else{
				this.getFormTpl(); //超管后台查看模板
			}
		}else{
			if(this.userId){
				this.getTplFormPreview(this.formId,this.userId) //用户预览模板中心的模板
			}else if(this.quesDataId){
				this.getUserFormData(this.formId,this.quesDataId); // 查看答题后的数据
			}else{
				if(this.logicFlag){
					this.getFormData();// 查看详情页，未答题前
				}else{
					this.getTplFormData(this.formId);//预览用户添加的模板
				}
			}
		}
		this.jzHost = document.referrer;
	},
	methods: {
		// 获取模板表单数据
		getTplFormData(formId){
			const _this = this;
			get('/formadmin/quesmodel/ques_preview.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.jsonDate);
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					console.log(formObj)
					_this.formId = formObj._id;
					_this.globalItemStyle = formObj.globalItemStyle?formObj.globalItemStyle:_this.globalItemStyle;
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取模板表单数据
		getFormData(){
			const _this = this;
			get('/formadmin/quesmodel/ques_date_add.jhtml',_this.astrictControl).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.jsonDate);
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					_this.formId = formObj._id;
					_this.verifyType = res.data.verifyType?res.data.verifyType:0;
					if(_this.verifyType == 1){
						_this.getGifCode()
					}
					_this.urlType = res.data.urlType?res.data.urlType:0;
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		getGifCode(){
			const _this = this;
			getGifCode('/com/gifCode.jhtml',{rand:'ques'}).then(res => {
				_this.verificationCode = 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取用户表单数据
		getUserFormData(formId,quesDataId){
			const _this = this;
			get('/formadmin/quesmodel/quesDataDetail.jhtml',{formId,quesDataId}).then(res => {
				if(res.code == 200){
					_this.logicFlag = true;
					_this.list = JSON.parse(res.data.jsonDate);
					_this.globalStyle = res.data.pcStyle;
					_this.mGlobalStyle = res.data.mobileStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					_this.mGlobalStyle.formCover.isShow = false
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取模板中心表单详情
		getFormTpl(){
			const _this = this;
			let param = {
				formId:_this.formId,
				// mobileStyle:_this.fromType,
				// pcStyle:_this.formTypeId
			}
			get('/superadm/formmodel/show.do',param).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):this.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):this.mGlobalStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 模板中心预览获取模板表单数据
		getTplFormPreview(formId){
			const _this = this;
			get('/formadmin/superAdm_preview.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.jsonDate);
					console.log(formObj)
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 加载样式
		loadStyle(){
			this.$nextTick(function () {
				const mFormHeadObj = this.mGlobalStyle.formHeader.textStyle;
				$(".mform-head .txt").css({
					fontSize: mFormHeadObj.fontSize + "px",
					color: mFormHeadObj.color,
					fontWeight: mFormHeadObj.fontWeight,
					fontStyle: mFormHeadObj.fontStyle,
					textDecoration: mFormHeadObj.textDecoration,
					textAlign: mFormHeadObj.textAlign == "居左"? "left":mFormHeadObj.textAlign == "居中"?"center":"right",
					backgroundColor: mFormHeadObj.backgroundColor,
					padding: mFormHeadObj.paddingVertical + "px " + mFormHeadObj.paddingHorizontal + "px",
				});
				$(".mform-head .img img").css({
					height: this.mGlobalStyle.formHeader.imgHeight + 'px'
				})
				const mFormTitleObj = this.mGlobalStyle.formTitle.textStyle;
				let mFormTitleBg = mFormTitleObj.bgType == "默认"? "unset":mFormTitleObj.bgType == "颜色"? mFormTitleObj.backgroundColor:"url(" + mFormTitleObj.backgroundImage + ")";
				$(".mform-title").css({
					fontSize: mFormTitleObj.fontSize + "px",
					color: mFormTitleObj.color,
					fontWeight: mFormTitleObj.fontWeight,
					fontStyle: mFormTitleObj.fontStyle,
					textDecoration: mFormTitleObj.textDecoration,
					padding: mFormTitleObj.paddingTop + "px " + mFormTitleObj.paddingHorizontal + "px " + mFormTitleObj.paddingBottom + 'px',
					background: mFormTitleBg,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				})
				const mFormDescriptObj = this.mGlobalStyle.formDescript.textStyle;
				$(".mform-descript").css({
					fontSize: mFormDescriptObj.fontSize + "px",
					color: mFormDescriptObj.color,
					fontWeight: mFormDescriptObj.fontWeight,
					fontStyle: mFormDescriptObj.fontStyle,
					textDecoration: mFormDescriptObj.textDecoration,
					padding: mFormDescriptObj.paddingVertical + "px " + mFormDescriptObj.paddingHorizontal + "px",
				});
				const mFormContObj = this.mGlobalStyle.contStyle;
				$(".mform-widget").css({
					padding: mFormContObj.paddingTop + "px " + mFormContObj.paddingHorizontal + "px " + mFormContObj.paddingBottom + 'px',
				});
				const mFormBgObj = this.mGlobalStyle.formBg;
				let mFormBg = '';
				if(mFormBgObj.isShow){
					if(mFormBgObj.type == '颜色'){
						mFormBg = mFormBgObj.color;
					}else{
						if(mFormBgObj.imgSrc){
							mFormBg = 'url(' + mFormBgObj.imgSrc + ')';
						}
					}
				}else{
					mFormBg = '#fff';
				}
				$(".mform-inner").css({
					background: mFormBg,
					backgroundSize: 'cover'
				});
				const mSubmitBtnObj = this.mGlobalStyle.submitBtnStyle;
				$(".msubmit_btn .el-button").css({
					width: mSubmitBtnObj.width + "px",
					height: mSubmitBtnObj.height + "px",
					borderWidth: mSubmitBtnObj.borderWidth + "px",
					borderStyle: mSubmitBtnObj.borderStyle,
					borderColor: mSubmitBtnObj.borderColor,
					borderRadius: mSubmitBtnObj.borderRadius + "px",
					backgroundColor: mSubmitBtnObj.backgroundColor,
					fontSize: mSubmitBtnObj.fontSize + "px",
					fontWeight: mSubmitBtnObj.fontWeight,
					fontStyle: mSubmitBtnObj.fontStyle,
					color: mSubmitBtnObj.color,
					textDecoration: mSubmitBtnObj.textDecoration,
				});
				$(".msubmit_btn").css({
					padding: mSubmitBtnObj.paddingTop + "px " + mSubmitBtnObj.paddingHorizontal + "px " + mSubmitBtnObj.paddingBottom + 'px',
					justifyContent: mSubmitBtnObj.textAlign == '居左'?'flex-start':mSubmitBtnObj.textAlign == '居中'?'center':'end',
				});
				const mFormCoverObj = this.mGlobalStyle.formCover;
				if(mFormCoverObj.isShow){
					$(".mform-cover").css({
						color:mFormCoverObj.titleStyle.color,
						fontSize: mFormCoverObj.titleStyle.fontSize+'px',
						background: mFormCoverObj.type == '颜色'?mFormCoverObj.color:'url(' + mFormCoverObj.imgSrc + ')',
						backgroundSize:'cover'
					});
					$(".mform-inner").css({
						"overflow":"hidden"
					})
					$(".mform-cover .btn-cover .el-button").css({
						color: mFormCoverObj.btnStyle.color,
						backgroundColor: mFormCoverObj.btnStyle.btnBg,
						borderColor: mFormCoverObj.btnStyle.btnBg,
					});
					$(".mform-cover .title-cover").css({
						top:mFormCoverObj.titleStyle.align == '上'? '20%':mFormCoverObj.titleStyle.align == '中'?'40%':'70%'
					});
				}
				
			})
		},
		
		// 表单提交
		formSubmit(){
			const _this = this;
			for (let i = 0; i < _this.list.length; i++) {
				const e = _this.list[i];
				if(e.logicShow && e.jumpShow && e.topic_required){
					if(e.modelType == 'matrix_radio' || e.modelType == 'matrix_checked' || e.modelType == 'matrix_scale'){
						e.value.forEach(item=>{
							if(!item){
								_this.$message.error(`${e.topicName}不能为空！`);
								return false;
							}
						})
							
					}else if(e.modelType == 'lc-classify'){
						let ans = false;
						e.matrixTopic.forEach(item=>{
							if(item.children.length>0){
								ans = true;
							}
						})
						if(!ans){
							_this.$message.error(`${e.topicName}不能为空！`);
							return false;
						}else{
							e.value = e.matrixTopic;
						}
					}else{
						if(e.value === '' || e.value.length == 0){
							_this.$message.error(`${e.topicName}不能为空！`);
							return false;
						}
					}
					
				}
			}
			let jsonData = {
				_id: _this.formId,
				quesDate: _this.list,
				pcStyle:_this.globalStyle,
				mobileStyle:_this.mGlobalStyle,
			};
			let params = {
				verifyType:_this.verifyType,
				verificationCode:_this.form.gifCode,
				formObj:JSON.stringify(jsonData),
			}
			if(_this.verifyType == 1 && _this.form.gifCode == ''){
				_this.$message.error(`验证码不能为空！`);
				return false;
			}else{
				post('/formadmin/quesmodel/date_commit.jhtml',params).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '提交成功！',
							onClose(){
								if(_this.identityType != 'user'){
									if(_this.urlType == 1){
										if(res.data.linkHtml != ''){
											window.location.href = res.data.linkHtml;
										}else{
											window.location.href = "https://bd.ruiyi126.com/";
										}
									}else{
										_this.quesDataId = res.data.formDateId
										_this.$router.push({
											path:'/ques_detail',
											query:{
												dataId:_this.quesDataId,
												formId:_this.formId
											}
										})
										window.location.reload()
									}
								}
							}
						})
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		},
		// 关闭手机封面，上划开始
		handleTouchStart(event) {
			this.startY = event.touches[0].clientY;
			this.startX = event.touches[0].clientX;
		},
		// 关闭手机封面，上划结束
		handleTouchEnd(event) {
			this.endY = event.changedTouches[0].clientY;
			this.endX = event.changedTouches[0].clientX;
			let X = this.endX - this.startX;
			let Y = this.endY - this.startY;
			if (Y < 0 && Math.abs(Y) > Math.abs(X)) {
				// 向上滑动
				this.mGlobalStyle.formCover.isShow = false;
			}
		},
		// 关闭手机封面，开始按钮
		closeCover() {
			this.mGlobalStyle.formCover.isShow = false;
			$(".mform-inner").css({
				"overflow-y":"auto"
			})
		},
	}
};
</script>
<style lang="less" scoped>
	.mform-inner{
		width: 100%;
		height: 100%;
		border: 1px solid #ddd;
		overflow-y: auto;
		position: relative;
		.mform-head{
			.img{
				img{
					width: 100%;
				}
			}
		}
		.mform-title{
			text-align: center;
		}
		.mform-item{
			position: relative;
			.cover{
				position: absolute;
				left: 0;top: 0;right:0;
				bottom: 0;z-index: 10;
			}
		}
		.yzm{
			margin: 0 20px 0 0;
		}
		.msubmit_btn{
			display: flex;
		}
		.mform-cover{
			width:100%;
			height: 100%;
			position: absolute;
			top:0;
			left: 0;
			z-index: 10;
			.title-cover{
				position: absolute;
				width: 100%;
				left: 0;
				top:40px;
				text-align: center;
			}
			.btn-cover{
				.el-button{
					position:absolute;
					width:80%;
					left:10%;
					right: 10%;
					bottom: 40px;
				}
			}
		}
		.limit-page{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			padding: 0 0 20vh;
			.el-image{
				.el-icon-lock{
					font-size: 70px;
					color: #ccc;
				}
			}
			.pass-word{
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 5vh 0 0;
				.el-input{
					margin: 0 10px 0 0;
				}
			}
		}
		.cant-answer{
			height: 100%;
			.el-result{
				height: 90%;
			}
		}

	}
</style>