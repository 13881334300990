<template>
  <div class="form-preview">
    <div class="top">
      <div></div>
      <div class="equipment_switch">
						<div class="btn el-icon-monitor" :class="{'active': equipment == 1}" @click="equipment = 1"></div>
						<div class="btn el-icon-mobile" :class="{'active': equipment == 2}" @click="equipment = 2"></div>
      </div>
      <div class="close el-icon-close" @click="back"></div>
    </div>
    <div class="body" :class="{'ruiyiStyle':ruiyiStyle}">
      <!-- PC端 -->
      <div class="preview-pc" v-show="equipment == 1">
        <previewPC  :logicFlag="logicFlag"/>
      </div>
      <!-- 移动端 -->
      <div class="preview-mobile" v-show="equipment == 2">
        <div class="mobile-modle">
          <previewMobile  :logicFlag="logicFlag"/>
        </div>
        <!-- 扫码预览 -->
        <div class="scan-preiview" v-if="!ruiyiStyle">
          <div class="title">手机扫码查看效果</div>
          <div class="tips">* 预览只能查看效果，无法提交数据</div>
          <div class="code_img" ref="qrCodeUrl"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
import previewPC from "@/views/ques/previewPC";
import previewMobile from "@/views/ques/previewMobile";
export default {
  components: {
    previewPC,
    previewMobile,
  },
  data () {
    return {
      logicFlag:false,
      equipment: 1,
      ruiyiStyle:false
    }
  },
  created () {
     
  },
  mounted () {
    this.changeTheme()
      this.creatQrCode();
  },
  methods: {
    // 瑞蚁官网模板预览页修改效果
		changeTheme(){
			const _this = this;
			window.addEventListener('message', function(event) {console.log(event)
				let obj = JSON.parse(event.data)
					if (obj['ruiyi-mobile']) {
						_this.ruiyiStyle = true;
					}
				}, false);
			},
    creatQrCode () {
      const url = window.location.origin + '/ques_previewMobile?formId=' + this.$route.query.formId;
      new QRCode(this.$refs.qrCodeUrl, {
        text: url,
        width: 160,
        height: 160,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.L
      })
    },
    back () {
      if(sessionStorage.getItem('referrer')){
				sessionStorage.removeItem('formId');
				window.location.href = sessionStorage.getItem('referrer') + '/formCloseBack.html'
			}else{
        this.$router.go(-1);
      }
    }
  },

};
</script>
<style lang="less" scoped>
.form-preview {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
/deep/ .el-radio__label, /deep/  .el-checkbox__label{white-space: pre-wrap;}
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0;
    position: relative;
    overflow: hidden;

    .equipment_switch {
      display: flex;
      width: 90px;
      height: 34px;

      .btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        background-color: #fff;
        font-size: 22px;
        color: #999;
        cursor: pointer;

        &:hover {
          color: var(--theme-color);
        }
      }

      .el-icon-monitor {
        border-right: none;
        border-radius: 3px 0 0 3px;
      }

      .el-icon-mobile {
        border-left: none;
        border-radius: 0 3px 3px 0;
      }

      .btn.active {
        background-color: var(--theme-color);
        border-color: var(--theme-color);
        color: #fff;
      }
    }

    .close {
      font-size: 30px;
      color: #999;
      cursor: pointer;
      transition: transform .2s;

      &:hover {
        transform: rotate(90deg);
        transition: transform .2s;
        color: var(--theme-color);
      }
    }
  }

  .body {
    flex: 1;
    overflow: hidden;
      &.ruiyiStyle{
        .preview-pc {
          /deep/ .form-inner{
            width:95%!important;
            max-width: 800px;
          }
        }
        .preview-mobile{
          padding: 0;
          .mobile-modle{
            background:none;
            height: 500px;
            width: 380px;
            padding: 20px;
          }
        }
      }
    .preview-pc {
      height: 100%;
      padding: 20px auto;
      box-sizing: border-box;
    }

    .preview-mobile {
      display: flex;
      justify-content: center;
      padding: 0 0 0 300px;
      overflow-y: auto;
      height: 100%;
      .mobile-modle {
        width: 447px;
        height: 863px;
        padding: 91px 36px 105px;
        background: url(../../assets/images/appear-mobile.png);
        overflow: hidden;
      }

      .scan-preiview {
        padding: 100px 0 0 100px;
        text-align: center;

        .title {
          font-size: 16px;
        }

        .tips {
          margin: 10px 0 20px;
          font-size: 12px;
          color: var(--theme-orange-color);
        }

        .code_img {
          width: 180px;
          height: 180px;
          padding: 10px;
          margin: 0 auto;
          background-color: #fff;
          border-radius: 5px;
          overflow: hidden;

        }
      }
    }

  }
}</style>